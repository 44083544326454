import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faQuestion } from "@fortawesome/free-solid-svg-icons";
import AllTeamsList from "./components/List";
import { useNavigate } from "react-router-dom";
import { getAllMatch } from "../../api/match/matchAction";
import { useDispatch } from "react-redux";

export default function AllTeamPage(props) {
  
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  // const [data, setData] = useState([]);
  const [row, setRow] = useState({});
  
  const [imagePreview, setImagePreview] = useState(null);
  const isFetch = useRef(false);
  const navigate = useNavigate();

  

  const handleNavigate = () => {
    navigate("/admin/match/question");
  };
  
  const handleShow = () => {
    setImagePreview(null);
   //setShow(true);
  };
  const handleEdit = (row) => {
    navigate("/admin/team/featured"); 
  }

  
  const columns = [
    {
      name: "S.No",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Teams",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <button
            onClick={() => handleEdit(row)}
            className="btn btn-secondary me-2"
            title="Edit"
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </div>
      ),
    },
  ];
  const data = [
    {
      id: 1,
      name: "India",
    },
    {
      id: 2,
      name: "Australia",
    },
    {
      id: 3,
      name: "England",
    },
    {
      id: 4,
      name: "Pakistan",
    },
    {
      id: 5,
      name: "Sri Lanka",
    },
  ];
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  
  // useEffect(()=> {
  //   if(!isFetch.current && data) {
  //     dispatch(getAllMatch());
  //     setData(data)
  //     isFetch.current = true;
  //   }
  // },[])


 

  return (
    <>
      <AllTeamsList
        data={data}
        columns={columns}
        handleShow={handleShow}
      />
      {/* <AllEventFormDialogue
        show={show}
        row={row}
        handleClose={handleClose}
        handleImageChange={handleImageChange}
        imagePreview={imagePreview}
        addTeam={addTeam}
        removeTeam={removeTeam}
        setTeams={setTeams}
        teams={teams}
      /> */}
    </>
  );
}
