import * as actionTypes from "./leagueTemplateActionType";
import axios from "axios";
const { REACT_APP_CRICKET_URL } = process.env;
 
export const getAllLeagueTemplate = () => {
  const headers = {
    'status': "ACTIVE",            
    'allowauto': "ACTIVE", 
  };
    return (dispatch) => {
        dispatch(getAllLeagueInitTemplate());
        axios.get(`${REACT_APP_CRICKET_URL}/admin/v1/leaguetemplete`, { headers: headers })
          .then((response) => {
            dispatch(getAllLeagueTemplateSuccess(response.data));
          })
          .catch(function (error) {
            dispatch(getAllLeagueTemplateFailure());
          });
      };
}
export const getAllLeagueInitTemplate = () => ({
    type: actionTypes.GET_LEAGUE_TEMPLATE_ALL,
});
export const getAllLeagueTemplateFailure = () => ({
    type: actionTypes.GET_ALL_LEAGUE_TEMPLATE_FAILURE,
});
export const getAllLeagueTemplateSuccess = (data) => ({
    type: actionTypes.GET_ALL_LEAGUE_TEMPLATE_SUCCESS,
    payload: { data },
});


export const createLeagueTemplate = (data) => {
  const headers = {
    'status': data.status !== undefined && data.status !== null ? data.status : false,
    'allowauto': data.allowauto !== undefined && data.allowauto !== null ? data.allowauto : false,
  };
  if (typeof data.marginPercentage === 'number') {
    data.marginPercentage = parseFloat(data.marginPercentage.toFixed(1));
  } 
  
  data.totalParticipants = parseInt(data.totalParticipants, 10);
  data.winners =  parseInt(data.winners, 10);
  return (dispatch) => {
      dispatch(createLeagueTemplateInit());
      axios.post(`${REACT_APP_CRICKET_URL}/admin/v4/leaguetemplete`, data, {} )
        .then((response) => {
          dispatch(createLeagueTemplateSuccess(response.data));
        })
        .catch(function (error) {
          dispatch(createLeagueTemplateFailure());
        });
    };
}

 


export const createLeagueTemplateInit = () => ({
  type: actionTypes.CREATE_LEAGUE_TEMPLATE_ALL,
});
export const createLeagueTemplateFailure = () => ({
  type: actionTypes.CREATE_LEAGUE_TEMPLATE_FAILURE,
});
export const createLeagueTemplateSuccess = (data) => ({
  type: actionTypes.CREATE_LEAGUE_TEMPLATE_SUCCESS,
  payload: { data },
});

