import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash, faDesktop  } from "@fortawesome/free-solid-svg-icons";
import ResultsList from "./components/List";
import { useNavigate } from "react-router-dom";
import ResultFormDialogue from "./components/Form";
import ResultDetail from "./components/Detail";
import { useDispatch } from "react-redux";
import { getAllUser } from "../../../api/user/userAction";

export default function ManageUsersPage(props) {
  
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [row, setRow] = useState([]);
  const [show, setShow] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate()
  const handleClose = () => setShow(false);
  const isFetch = useRef(false);

  const handleShow = () => {
    setImagePreview(null);
    setShow(true);
  };
  const handleEdit = (row) => {
    console.log(`Edit clicked for ID: ${row.id}`);
    setShow(true);
  };

 

  const handleRedirect = (row) => {
    setRow(row);
    navigate('/admin/user-detail');
  }
  
  useEffect(()=> {
    dispatch(getAllUser());
    setData(data)
    isFetch.current = true;
  },[!isFetch.current])

  useEffect(()=> {
    if(data) {
      setData(data)
    }
  },[data])

  useEffect(() => {
    if(props.result && Array.isArray(props.result.data)) {
      const updateData  =  props.result.data.map((item, index) => ({
        ...item,
        s_n: index + 1,
         
      }))
      setData(updateData);
    }
  },[props.result])

  const columns = [
    {
      name: "SN",
      selector: (row) => row.s_n,
      sortable: true,
    },
    {
      name: "User Name",
      selector: (row) => row.username,
    },
    {
      name: "name",
      selector: (row) => row.name,
    },
    {
      name: "EMAIL",
      selector: (row) => row.emailid,
      sortable: true
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
    },
    {
      name: "Birth Date",
      selector: (row) => row.birthDate,
    },
    {
      name: 'ACTION',
      cell: row => (
        <div>
          <button 
            onClick={() => handleRedirect({row})} 
            className="btn btn-link"
            title="Edit"
          >
            <FontAwesomeIcon icon={faDesktop} />
          </button>
        </div>
      ),
    },
  ];

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  return (
    <>
      
      <ResultFormDialogue
        show={show}
        handleClose={handleClose}
        handleImageChange={handleImageChange}
        imagePreview={imagePreview}
      />
      {row && Object.keys(row).length > 0 ? (
            <ResultDetail row={row} />
          ) : (
            <ResultsList
              data={data}
              columns={columns}
              handleShow={handleShow}
            />
          )}
          
      
    </>
  );
}
