import  React, { useState } from "react";
import {  Button,Modal,Form, FormControl, Row, Col, Container, Card, CardBody,Badge,  } from "react-bootstrap";
import { FaPlus, FaMinus } from 'react-icons/fa';
import Table from 'react-bootstrap/Table';
import { useLocation } from 'react-router-dom';

export default function SingleEventFormPage(props) {
    const location = useLocation();
    const [player, setPlayer] = useState();
    const row = location.state?.row;  
    const teams = row.teams.split('|');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleCloseModal = () => setIsModalVisible(false);
    // const handleOpenModal = () => setIsModalVisible(true);
    const handleOpenModal = (player) => {
      setPlayer(player);
      console.warn('players :: ',player);
      setIsModalVisible(true)
    };
    
    
    return (
        <>
        <Container>
          <Card>
            <CardBody>
              
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>{row?.name ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th>Short Name</th>
                  <td>{row?.short_name ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th>Sub Title</th>
                  <td>{row?.sub_title ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th>Teams</th>
                  <td className="d-flex gap-2">
                    <Table striped bordered hover >
                      <tbody>
                        <tr>
                          <th colSpan={2} className="text-dark text-center bg-warning">Team {teams?.[0] ?? 'N/A'}</th>
                        </tr>
                      </tbody>
                    </Table>
                    <Table striped bordered hover >
                      <tbody>
                        <tr>
                          <th colSpan={2} className="text-dark text-center bg-warning">Team {teams?.[1] ?? 'N/A'}</th>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                </tr>
                <tr>
                  <th>Start At</th>
                  <td>{row?.start_at ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th>Venue</th>
                  <td>
                    <ul style={{listStyle:'none',marginLeft:'-1.9rem'}}>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Name: </b> {row?.venue?.name ?? 'N/A' } </li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>City: </b> {row?.venue?.city ?? 'N/A' }</li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Country: </b>  {row?.venue?.country?.country ?? 'N/A' }</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>Tournament</th>
                  <td>
                    <ul style={{listStyle:'none',marginLeft:'-1.9rem'}}>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Name: </b> {row?.tournament?.name ?? 'N/A' }</li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Short Name: </b> {row?.tournament?.short_name ?? 'N/A' }</li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Alternate Name: </b> {row?.tournament?.alternate_name ?? 'N/A' }</li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Alternate Short Name: </b> {row?.tournament?.alternate_short_name ?? 'N/A' }</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>Association</th>
                  <td>
                    <ul style={{listStyle:'none',marginLeft:'-1.9rem'}}>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Country: </b> {row?.association?.name ?? 'N/A' }</li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Code: </b> {row?.association?.short_name ?? 'N/A' }</li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Name: </b> {row?.association?.alternate_name ?? 'N/A' }</li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Parent: </b> {row?.association?.alternate_short_name ?? 'N/A' }</li>
                    </ul>
                  </td>
                </tr>
               <tr>
                  <th>Metric Group</th>
                  <td>{row?.metric_group ?? 'N/A' }</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>{row?.status ?? 'N/A' }</td>
                </tr>
                <tr>
                  <th>Winner</th>
                  <td>{row?.winner ?? 'N/A' }</td>
                </tr>
                <tr>
                  <th>Messages</th>
                  <td>{row?.messages ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th>Gender</th>
                  <td>{row?.gender ?? 'N/A' }</td>
                </tr>
                <tr>
                  <th>Sport</th>
                  <td>{row?.sport ?? 'N/A' }</td>
                </tr>
                <tr>
                  <th>Format</th>
                  <td>{row?.format ?? 'N/A' }</td>
                </tr>
                <tr>
                  <th>Title</th>
                  <td>{row?.title ?? 'N/A' }</td>
                </tr>
                <tr>
                  <th>Play Status</th>
                  <td>{row?.play_status ?? 'N/A' }</td>
                </tr>
                <tr>
                  <th>Start At Local</th>
                  <td>{row?.start_at_local ?? 'N/A' }</td>
                </tr>
                <tr>
                      <th>Toss</th>
                      {
                  row?.toss ? (
                      <td>
                        <ul style={{ listStyle: 'none', marginLeft: '-1.9rem' }}>
                          <li><b style={{ width: '12rem', display: 'inline-block' }}>Called: </b> { row?.toss?.called }</li>
                          <li><b style={{ width: '12rem', display: 'inline-block' }}>Winner: </b> {row?.toss?.winner }</li>
                          <li><b style={{ width: '12rem', display: 'inline-block' }}>Elected: </b> {row?.toss?.elected }</li>
                          <li><b style={{ width: '12rem', display: 'inline-block' }}>Squad Announced: </b> {row?.toss?.squad_announced }</li>
                        </ul>
                      </td>
                  ) : 'N/A'
                }
                </tr>
                
                <tr>
                  <th>Play</th>
                  <td>{row?.play ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th>Play Status</th>
                  <td>{row?.play_status ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th>Players {row?.squad?.a ? teams[0] : 'N/A'}</th>
                  <td>
                    <div className="d-flex gap-2 flex-wrap">
                    {
                      row?.squad?.a?.player_keys && row.squad.a.player_keys.length > 0 ? (
                        row.squad.a.player_keys.map((playerKey, index) => {
                          const player = row?.players?.[playerKey]?.player;
                          return player ? (
                            <button key={index} className="btn btn-primary btn-sm" onClick={() => handleOpenModal(player)}>
                              {player?.name ?? 'N/A'}
                            </button>
                          ) : null;  
                        })
                      ) : (
                        "N/A"
                      )
                    }

                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Players {row?.squad?.b ? teams[1] : 'N/A'}</th>
                  <td>
                    <div className="d-flex gap-2 flex-wrap">
                    {
                      row?.squad?.b?.player_keys && row?.squad?.b?.player_keys?.length > 0 ? (
                        row?.squad?.b?.player_keys.map((playerKey, index) => {
                          const player = row?.players?.[playerKey]?.player;
                          return player ? (
                            <button key={index} className="btn btn-primary btn-sm" onClick={() => handleOpenModal(player)}>
                              {player?.name ?? 'N/A'}
                            </button>
                          ) : null;  
                        })
                      ) : (
                        "N/A"
                      )
                    }

                    </div>
                  </td>
                </tr>

                <tr>
                  <th>Data Review</th>
                  <td>
                    <ul style={{listStyle:'none',marginLeft:'-1.9rem'}}>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Schedule: </b> {row?.data_review?.schedule ?? 'N/A'}</li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Venue: </b> {row?.data_review?.venue ?? 'N/A'}</li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Result: </b> {row?.data_review?.result ?? 'N/A'}</li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Pom: </b> {row?.data_review?.pom ?? 'N/A'}</li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Score: </b> {row?.data_review?.score ?? 'N/A'}</li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Players: </b> {row?.data_review?.players ?? 'N/A'}</li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Playing XI: </b> {row?.data_review?.playing_xi ?? 'N/A'}</li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Team a: </b> {row?.data_review?.team_a ?? 'N/A'}</li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Team b: </b> {row?.data_review?.team_b ?? 'N/A'}</li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Good To Close: </b> {row?.data_review?.good_to_close ?? 'N/A'}</li>
                      <li><b style={{width:'12rem',display:'inline-block'}}>Note: </b> {row?.data_review?.note ?? 'N/A'}</li>
                      <li>
                          {/* this section needs some clarity */}
                          <b style={{width:'15rem',display:'inline-block',margin:'1rem 0'}}>Score Reviewed Ball Index: </b> 
                          <Table striped bordered hover>
                            <tbody>
                              <tr>
                                  <th>Ball ID</th>
                                  <th>Score 1</th>
                                  <th>Score 2</th>
                              </tr>
                              <tr>
                                <td>{row?.score_reviewed_ball_index && row?.score_reviewed_ball_index[0] ? row?.score_reviewed_ball_index[0] : 'N/A'}</td>
                                <td>{Array.isArray(row?.score_reviewed_ball_index) && row?.score_reviewed_ball_index[1] ? row?.score_reviewed_ball_index[1][0] : 'N/A'}</td>
                                <td>{Array.isArray(row?.score_reviewed_ball_index) && row?.score_reviewed_ball_index[1] ? row?.score_reviewed_ball_index[1][1] : 'N/A'}</td>
                              </tr>

                            </tbody>
                          </Table>
                      </li>
                      
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>Squad</th>
                  <td className="d-flex gap-2">
                    <Table striped bordered hover >
                      <tbody>
                        <tr>
                          <th colSpan={2} className="text-dark text-center bg-warning">Team { row?.squad?.a ? teams[0]  : 'N/A' }
                          </th>
                        </tr>
                        <tr>
                          <th>Playing XI</th>
                          <td style={{ display: 'flex', gap: '5px', width: '300px', overflowX: 'auto', overflowY: 'hidden' }}>
                                {
                                  Array.isArray(row?.squad?.a?.playing_xi) && row?.squad?.a?.playing_xi.length > 0 ? (
                                    row?.squad?.a?.playing_xi.map((value, index) => (
                                      <Badge key={index} bg="primary">{value}</Badge>
                                    ))
                                  ) : "N/A"
                                }
                          </td>

                          
                        </tr>
                        <tr>
                          <th>Captain</th>
                          <td>{ row?.squad?.a && row?.squad?.a?.captain ? row?.squad?.a?.captain   : 'N/A' }</td>
                        </tr>
                        <tr>
                          <th>Keeper</th>
                          <td>{ row?.squad?.a && row?.squad?.a?.keeper ? row?.squad?.a?.keeper   : 'N/A' }</td>
                        </tr>
                        <tr>
                          <th>Replacements</th>
                          {
                            Array.isArray(row?.squad?.a?.replacements) && row?.squad?.a?.replacements.length > 0 ? 
                            <td style={{ display: 'flex',
                              gap: '5px',
                              width: '300px',
                              overflowX: 'auto', 
                              overflowY: 'hidden', }}>
                              <Badge bg="secondary">Player1</Badge>
                              <Badge bg="secondary">Player2</Badge>
                            </td>
                            : 'N/A'
                          }
                        </tr>
                      </tbody>
                    </Table>
                    <Table striped bordered hover >
                      <tbody>
                        <tr>
                          <th colSpan={2} className="text-dark text-center bg-warning">Team { row?.squad?.b ? teams[1]  : 'N/A' }</th>
                        </tr>
                        <tr>
                          <th>Playing XI</th>
                          <td style={{ display: 'flex', gap: '5px', width: '300px', overflowX: 'auto', overflowY: 'hidden' }}>
                                {
                                  Array.isArray(row?.squad?.b?.playing_xi) && row?.squad?.b?.playing_xi.length > 0 ? (
                                    row?.squad?.b?.playing_xi.map((value, index) => (
                                      <Badge key={index} bg="primary">{value}</Badge>
                                    ))
                                  ) : "N/A"
                                }
                          </td>
                        </tr>
                        <tr>
                          <th>Captain</th>
                          <td>{ row?.squad?.b && row?.squad?.b?.captain ? row?.squad?.b?.captain   : 'N/A' }</td>
                        </tr>
                        <tr>
                          <th>Keeper</th>
                          <td>{ row?.squad?.b && row?.squad?.b?.keeper ? row?.squad?.b?.keeper   : 'N/A' }</td>
                        </tr>
                        <tr>
                          <th>Replacements</th>
                          {
                            Array.isArray(row?.squad?.b?.replacements) && row?.squad?.b?.replacements.length > 0 ? 
                            <td style={{ display: 'flex',
                              gap: '5px',
                              width: '300px',
                              overflowX: 'auto', 
                              overflowY: 'hidden', }}>
                              <Badge bg="secondary">Player1</Badge>
                              <Badge bg="secondary">Player2</Badge>
                            </td>
                            : 'N/A'
                          }
                          
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                </tr>
                <tr>
                  <th>Estimated End Date</th>
                  <td>{row?.estimated_end_date ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th>Completed Date Approximate</th>
                  <td>{row?.completed_date_approximate ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th>Umpires</th>
                  <td>{row?.umpires ?? 'N/A'}</td>
                </tr>
                <tr>
                  <th>Auto League Create Status</th>
                  <td>{row?.autoLeagueCreate_Status ?? 'N/A'}</td>
                </tr>
              </tbody>
            </Table>

            {/* player detail modal  */}
            <Modal show={isModalVisible} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Player { player?.name ?? 'N/A'}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                <Table striped bordered hover >
                      <tbody>
                        <tr>
                          <th>Name</th>
                          <td>{player?.name ?? 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Jersey Name</th>
                          <td>{player?.jerseyName ?? 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Legal Name</th>
                          <td>{player?.legalName ?? 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Gender</th>
                          <td>{player?.gender ?? 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Nationality</th>
                          <td>
                            <ul style={{listStyle:'none',marginLeft:'-1.9rem'}}>
                                <li><b style={{width:'8rem',display:'inline-block'}}>Short Code: </b> {player?.nationality?.shortCode ?? 'N/A'}</li>
                                <li><b style={{width:'8rem',display:'inline-block'}}>Code: </b> {player?.nationality?.code ?? 'N/A'}</li>
                                <li><b style={{width:'8rem',display:'inline-block'}}>Name: </b> {player?.nationality?.name ?? 'N/A'}</li>
                                <li><b style={{width:'8rem',display:'inline-block'}}>Official Name: </b> {player?.nationality?.officialName ?? 'N/A'}</li>
                                <li><b style={{width:'8rem',display:'inline-block'}}>Is Region: </b> {player?.nationality?.isRegion ?? 'N/A'}</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <th>DOB</th>
                          <td>{player?.dateOfBirth ?? 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Roles</th>
                          <td>
                            <div className="d-flex gap-2 flex-wrap">
                              <Badge pill bg="warning" text="dark">{Array.isArray(player?.roles) ? player?.roles[0] : 'N/A'}</Badge>
                              {/* <Badge pill bg="warning" text="dark">Wicket Keeper</Badge> */}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>Seasonal Role</th>
                          <td>{player?.seasonalRole ?? 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Batting Style</th>
                          <td>{player?.battingStyle ?? 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Bowling Style</th>
                          <td>{player?.bowlingStyle ?? 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Skills</th>
                          <td>
                            <div className="d-flex gap-2 flex-wrap">
                              <Badge pill bg="secondary">{Array.isArray(player?.skills) ? player?.skills[0] : 'N/A'}</Badge>
                              <Badge pill bg="secondary">{Array.isArray(player?.skills) ? player?.skills[1] : 'N/A'}</Badge>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>Legal Name </th>
                          <td>{player?.legalName ?? 'N/A'}</td>
                        </tr>
                        <tr>
                          <th>Legal Name v2</th>
                          <td>{player?.legalNameV2 ?? 'N/A'} </td>
                        </tr>
                      </tbody>
                    </Table>
                </div>
              </Modal.Body>
            </Modal>

            </CardBody>
          </Card>
        </Container>
        
        </>
    )
}


 