import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faDesktop  } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import DetailPage from "./components/DetailPage";

export default function UserDetailPage(props) { 
  const { row }= props;
  const [show, setShow] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [ data,setData] = useState([]);
  const navigate = useNavigate()
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setImagePreview(null);
    setShow(true);
  };
   
  const handleRedirect = () => {
    navigate('/admin/who-will-win');
  }
  
  const data1 = [
    {
      id: 1,
      name: "name1",
      email: "xyz@gmail.com",
      mobile: "885945678",
      balance: "0.00 USD",
    },

  ];

  const columns = [
    {
      name: "SN",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "NAME",
      selector: (row) => row.name,
    },
    {
      name: "EMAIL",
      selector: (row) => row.email,
      sortable: true
    },
    {
      name: "MOBILE",
      selector: (row) => row.mobile,
    },
    {
      name: "BALANCE",
      selector: (row) => row.balance,
    },
    {
      name: 'ACTION',
      cell: row => (
        <div>
          <button 
            onClick={() => handleRedirect()} 
            className="btn btn-link"
            title="Edit"
          >
            <FontAwesomeIcon icon={faDesktop} />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if(props?.result?.data) {
      setData(props?.result?.data);
    }
  },[props?.result?.data])

  useEffect(() => {
    if(data) {
      //const filterData = data && data.filter((value,index) => value.id == row.id)
      
    }
  },[data])

  return (
    <>
      <DetailPage />
    </>
  );
}
