import React, { useState } from 'react';
import './Drawer.css';

const LeagueDrawerComponent = (props) => {
    const {singleRowData, isOpen, toggleDrawer} = props
    return (
        <div>
            <div className={`drawer ${isOpen ? 'open' : ''}`}>
                <button onClick={toggleDrawer} className="close-button">
                    &times;
                </button>

                <table>
                    <tbody>
                        <tr>
                            <th>Total Paricipants :</th>
                            <td>{singleRowData?.totalParticipants ?? 'N/A' }</td>
                        </tr>
                        <tr>
                            <th>Entry Fees :</th>
                            <td>{singleRowData?.entryfees ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Margin Percentage :</th>
                            <td>{singleRowData?.marginPercentage ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Max Prize Pool :</th>
                            <td>{singleRowData?.maxPrizePool ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Level Percentages :</th>
                            <td>{singleRowData?.percentagesofpricelevel?.map((item, index) => (<span key={index}>{item}, </span>))}</td>
                        </tr>
                        <tr>
                            <th>Range of Ranks :</th>
                            <td>{singleRowData?.rangesofRanks?.map((item, index) => (<span key={index}>{item}, </span>))}</td>
                        </tr>

                        <tr>
                            <th>Status :</th>
                            <td>{singleRowData?.status ?? 'N/A'}</td>
                        </tr>

                         
                    </tbody>
                </table>
            </div>
            {isOpen && <div className="backdrop" onClick={toggleDrawer}></div>}
        </div>
    );
};

export default LeagueDrawerComponent;
