import React, { useState,useMemo } from 'react';
import { Col } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import '../../src/index.css';
const DataTableComponent = ({ data,columns, perPage,pageOption }) => {
 const [filterText, setFilterText] = useState('');
 const filteredItems = useMemo(() => {
  return data.filter(item => {
    // const fieldsToSearch = item.statename || item.name || item.user_id || item.status ;
    const fieldsToSearch = [
      item.statename || '',
      item.name || '',
      item.user_id || '',
      item.status || '',
      item.start_date || '',
      item.last_scheduled_match_date || '',
    ];
    
    return fieldsToSearch.some(field => {
      if (field === item.status) {
        return String(field).toLowerCase().startsWith(filterText.toLowerCase());
      }
      return String(field).toLowerCase().includes(filterText.toLowerCase());
    });
    return fieldsToSearch.some(field =>
      String(field).toLowerCase().includes(filterText.toLowerCase())
    );
    const valueToSearch = fieldsToSearch.filter(Boolean).join(' ')
    return valueToSearch ? valueToSearch.toLowerCase().includes(filterText.toLowerCase()): data;
    }
  );
}, [data, filterText]);
 
  return (
    <>
    <Col xs={3} className="float-end">
      <input
        type="text"
        placeholder="Filter by name"
        value={filterText}
        onChange={e => setFilterText(e.target.value)}
        className="form-control mb-3 "
        style={{padding:'.5rem 1rem'}}
      />
    </Col>
    <div>
      <DataTable
        columns={columns}
        data={filteredItems}
        pagination
        paginationPerPage={perPage}
        paginationRowsPerPageOptions={pageOption}
        noDataComponent="No data available"
        highlightOnHover
        striped
      />
    </div>
    </>
  );
};

export default DataTableComponent;
