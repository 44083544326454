import AllTeamPage from './AllTeam.component';
import { connect } from 'react-redux';
import { getAllMatch } from '../../api/match/matchAction';
const mapDispatchToProps = {
    getAllMatch
}
const mapStateToProps = state => ({
    isLoading: state.matchPage.isLoading,
    isSaved: state.matchPage.isSaved,
    result: state.matchPage.result,
    count: 1,
});
export const AllTeamPageContainer = connect(mapStateToProps, mapDispatchToProps)(AllTeamPage); 