import React, { useState } from "react";
import { Button, Modal, Form, FormControl, Row, Col } from "react-bootstrap";

export default function TournamentFormDialogue(props) {
  const { show,row, data,  setRow, handleClose,validate } = props;
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Tournament</Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => {
          e.preventDefault();
            validate(data,row);
          }}>
        <Modal.Body>
          <Row>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Name</b>
                  </Form.Label>
                  <Form.Control 
                      type="text" 
                      placeholder=""
                      value={row.name}
                      readonly="readonly"
                      onChange={(e) => setRow({
                        ...row,
                        name : e.target.value
                      })}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Short Name</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    readonly="readonly"
                    value={row?.short_name}
                    onChange={(e) => setRow({
                      ...row,
                      short_name : e.target.value
                    })}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Start Date</b>
                  </Form.Label>
                  <Form.Control 
                    type="date" 
                    placeholder=""
                    readonly="readonly"
                    value={row?.start_date}
                    onChange={(e) => setRow({
                      ...row,
                      start_date : e.target.value
                    })}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Last Schedule Match Date</b>
                  </Form.Label>
                  <Form.Control 
                    type="date" 
                    placeholder=""
                    readonly="readonly" 
                    value={row?.last_scheduled_match_date}
                    onChange={(e) => setRow({
                      ...row,
                      last_scheduled_match_date : e.target.value
                    })}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Country</b>
                  </Form.Label>
                  <Form.Select>
                  {row.countries && row.countries.map((val, index) => {
                      return (
                        <option key={index} value={val.name}>{val.name}</option>  
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Status</b> 
                  </Form.Label>
                    <Form.Select value={row?.status || ''} 
                    onChange={(e) => setRow({
                      ...row,
                      status : e.target.value
                    })}
                    
                    >
                      <option value="ACTIVE">Active</option>
                      {/* <option value="INACTIVE">Inactive</option> */}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="primary">
            Update
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
