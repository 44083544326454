import React, { useEffect, useState } from "react";
import { Button, Modal, Form, FormControl, Row, Col } from "react-bootstrap";

export default function LeagueFormDialogue(props) {
  const { show, handleClose, handleImageChange, imagePreview, row, setRow, validate,users,setTotalAmountPerPerson,totalAmountPerPerson, totalPerson, setTotalPerson, setRemainingPerson, remainingPerson, setTotalRemainingPerson, totalremainingPerson, setRemainingPersonPercent, remainingPersonPercent, setAllowInput, allowInput, allTotalRank, setAllTotalRank } = props;
  // const [fields, setFields] = useState([""]);
  const [fields, setFields] = useState([]);
  const handleAddField = () => {
    setFields([...fields, []]);
  };
  
  const handleFieldInputChange = (index, field, value) => {
    const updatedFields = [...fields];
    updatedFields[index][field] = value;
    setFields(updatedFields);
  };

  const handleBlurChange = (index, field, value) => {
    const newFields = [...fields];
    const updatedField = { ...newFields[index] };
    updatedField[field] = value;
    newFields[index] = updatedField;
    if(field == "rank") {
      const participateCount = handleRangeInput(value);
      const participateCounts = newFields.map((field) => handleRangeInput(field.rank));
      const totalParticipateCount = participateCounts.reduce((total, count) => total + Number(count), 0);
      console.warn('totalParticipateCount :: ',totalParticipateCount);
      setAllTotalRank(totalParticipateCount);
      console.warn('participateCounts :: ',participateCounts)
      
      if(parseInt(totalParticipateCount) > totalPerson) {
        alert(`Input value cannot exceed ${allowInput}`);
        return;
      } else {
        
        setRemainingPerson(prevRemaining => {
          if (prevRemaining === 0) {
            const newRemainingPerson = totalPerson - participateCount;
            return newRemainingPerson;
          } else {
            const updatedRemainingPerson = prevRemaining - participateCount;
            return updatedRemainingPerson >= 0 ? updatedRemainingPerson : prevRemaining;
          }
        });
        setRow((prevRow) => {
          const updatedRangesofRanks = [...prevRow.rangesofRanks];
          updatedRangesofRanks[index] = parseFloat(participateCount) || 0;  // Replace the value at the correct index
          return {
            ...prevRow,
            rangesofRanks: updatedRangesofRanks,
          };
        });
      }
    }
    else if (field == "percentage") {
      const percentage = parseFloat(value);
      const participateCount = handleRangeInput(updatedField.rank);
      console.warn('participateCount :: ',participateCount);
      if (!isNaN(percentage)) {
        let perPerson = (row.maxPrizePool * percentage) / 100;
        let amountPerPerson = Math.floor(perPerson / parseInt(participateCount));
        updatedField.amountPerPerson = amountPerPerson;
      
      }
      
      setRow(prevRow => ({
        ...prevRow,
        percentagesofpricelevel: newFields.map(field => parseFloat(field.percentage) || 0),
      }));
    }
    setFields(newFields);
  };

  
  
  const handleRangeInput = (range) => {
    const rankArr = range.split('-');
    if (Array.isArray(rankArr) && rankArr.length === 2) {
      const start = rankArr[0];
      const end = rankArr[1];
      if (!isNaN(start) && !isNaN(end)) {
        if (start <= end) {
          return end - start + 1;  
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } else {
      return rankArr[0];
    }
  };


  const handleDeleteField = (index) => {
    const newFields = fields.filter((_, idx) => idx !== index);
    setFields(newFields);
    const removedValues = row.rangesofRanks.filter((_, idx) => idx !== index);
    const percentagesofpricelevel = row.percentagesofpricelevel.filter((_, idx) => idx !== index);
    setRow(prevRow => ({
      ...prevRow,
      rangesofRanks: removedValues
    }));

    setRow(prevRow => ({
      ...prevRow,
      percentagesofpricelevel: percentagesofpricelevel
    }));

    const total1 = row.rangesofRanks
                    .filter(rank => !isNaN(rank))
                    .reduce((acc, rank) => acc + Number(rank), 0);
    setTotalRemainingPerson(total1);

    const totalVal = removedValues.reduce((acc, rank) => acc + rank, 0);
    const totalRem = parseInt(totalPerson) - totalVal;
    setRemainingPerson(parseInt(totalRem));
  };

  const TotalWinningPerson = (e) => {
      setRow({
        ...row,
        winners: e.target.value,
      });
      
      
      const winningPercentage = e.target.value / 100;
      const totalPerson = row.totalParticipants * winningPercentage;
      
      setTotalPerson(totalPerson)
  }

  useEffect(() => {
    setFields(fields);
  }, [fields])

  useEffect(() => {
    const total111 = row.rangesofRanks
                    .filter(rank => !isNaN(rank))
                    .reduce((acc, rank) => acc + rank, 0);
    if(totalremainingPerson != totalPerson) {
      setTotalRemainingPerson(total111);
    }
  }, [row.rangesofRanks]); 

  useEffect(() => {
    if (Array.isArray(row.percentagesofpricelevel)) {
      const totalPercent = row.percentagesofpricelevel.reduce((acc, percentage) => {
        const parsedPercentage = parseFloat(percentage); // Try to parse the percentage as a float
        if (isNaN(parsedPercentage)) {
          return acc;
        }
        return acc + parsedPercentage; 
      }, 0);
      setRemainingPersonPercent(totalPercent); 
    }
  }, [row.percentagesofpricelevel]);
  
  useEffect(() => {
    const allowVal = parseInt(totalPerson) - parseInt(totalremainingPerson);
    if(totalremainingPerson <= totalPerson) {
      setAllowInput(parseInt(allowVal));
    }
    console.warn('updated totalremainingPerson :: ',totalremainingPerson)
  }, [totalremainingPerson]);

  useEffect(() => {
    console.log("allowInput: ", allowInput);
  }, [allowInput]);

  useEffect(() => {
    console.warn('Updated allTotalRank :: ', allTotalRank); // Logs the updated value of allTotalRank
  }, [allTotalRank]);

  useEffect(() => {
    console.warn('Updated remainingPersonPercent :: ', remainingPersonPercent); // Logs the updated value of allTotalRank
  }, [remainingPersonPercent]);

  useEffect(() => {
    console.warn('Updated remainingPerson :: ', remainingPerson); // Logs the updated value of allTotalRank
  }, [remainingPerson]);

  useEffect(() => {
    console.warn('Updated totalremainingPerson :: ', totalremainingPerson); // Logs the updated value of allTotalRank
  }, [totalremainingPerson]);


  
  

  

  
 
 
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add League</Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => {
          e.preventDefault();
            validate(row);
          }}>
        <Modal.Body>
       
            <Row>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Total Participants</b>
                  </Form.Label>
                  <Form.Control 
                  type="number" 
                  placeholder="Total Participants"
                  name="totalParticipants"
                  required
                  onChange={(e) => {
                    const updatedEntryFees = row.entryfees;
                    const totalEntryFees = e.target.value * updatedEntryFees;
                    const marginAmount = (totalEntryFees * row.marginPercentage) / 100;
                    const winningAmount = totalEntryFees - marginAmount;
                    
                    setRow({
                      ...row,
                      totalParticipants: e.target.value,
                      maxPrizePool : winningAmount
                    });
                  }}
                  // onChange={(e) =>
                  //   setRow({
                  //     ...row,
                  //     totalParticipants: e.target.value
                  //   })
                  // }
                  />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Entry Fees</b>
                  </Form.Label>
                  <Form.Control 
                    type="number"
                    required
                    placeholder="Enter Fees"
                    name="entryfees"
                    onChange={(e) => {
                        const updatedEntryFees = e.target.value;
                        const totalEntryFees = row.totalParticipants * updatedEntryFees;
                        const marginAmount = (totalEntryFees * row.marginPercentage) / 100;
                        const winningAmount = totalEntryFees - marginAmount;
                      setRow({
                        ...row,
                        entryfees: e.target.value,
                        maxPrizePool : winningAmount
                      });
                    }}
                     
                  />
                </Form.Group>
              </Col>
              
              {/* <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Top Prize Pool</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    required
                    placeholder="Enter Total Prize Pool"
                    name="totalPrizePool"
                    onChange={(e) =>
                      setRow({
                        ...row,
                        totalPrizePool: e.target.value
                      })
                    }
                  />
                </Form.Group>
              </Col> */}
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Margin Percentage</b>
                  </Form.Label>
                  <Form.Control 
                    type="number"
                    required
                    placeholder="Enter Margin Percentage"
                    name="marginPercentage"
                    onChange={(e) => {
                      const updatedEntryFees = row.entryfees;
                      const totalEntryFees = row.totalParticipants * updatedEntryFees;
                      const marginAmount = (totalEntryFees * e.target.value) / 100;
                      const winningAmount = totalEntryFees - marginAmount;
                    setRow({
                      ...row,
                      marginPercentage: e.target.value,
                      maxPrizePool : winningAmount
                    });
                  }}
                    // onChange={(e) =>
                      
                    //   setRow({
                    //     ...row,
                    //     marginPercentage: e.target.value
                    //   })
                    // }
                  />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b> Max Prize Pool</b>
                  </Form.Label>
                  <Form.Control 
                    type="number"
                    readOnly
                    placeholder="Max Prize Pool" 
                    name="maxPrizePool"
                    value={row.maxPrizePool}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label> <b> Winners</b></Form.Label>
                  <Form.Select
                      value={row.winners} 
                      onChange={(e) => {TotalWinningPerson(e)}}
                      // onChange={(e) => {
                      //   setRow({
                      //     ...row,
                      //     winners: e.target.value,
                      //   });
                      // }}
                    >
                    <option value="">Select Winner</option>
                    <option value="5">5%</option>
                    <option value="10">10%</option>
                    <option value="20">20%</option>
                    <option value="25">25%</option>
                    <option value="30">30%</option>
                    <option value="40">40%</option>
                    <option value="50">50%</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Status</b>
                  </Form.Label>
                  <Form.Select
                    name="status"
                    style={{ padding: ".7rem 1rem" }}
                    aria-label="Default select example"
                    onChange={(e) =>
                      setRow({
                        ...row,
                        status: e.target.value
                      })
                    }
                  >
                    <option value="ACTIVE">Active</option>
                    <option value="INACTIVE">Inactive</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              
              <Col xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Level Percentages <span style={{ color: "green", fontSize: "12px" }}>TP {totalPerson} | Remaining {remainingPerson}</span></b>
                  </Form.Label>
                  { totalremainingPerson > totalPerson || remainingPersonPercent >= 100 ? <Button 
                            style={{ float: "right" }} 
                            variant="secondary" 
                            disabled
                            onClick={handleAddField}
                            size="sm" 
                          >
                            Add More
                          </Button> : 
                          <Button 
                            style={{ float: "right" }} 
                            variant="secondary" 
                            onClick={handleAddField}
                            size="sm" 
                          >
                          Add More
                        </Button>
                  }
                  
                  <div className="d-flex align-items-center mb-2 mt-3">
                    <Col xs={4}>
                      <Form.Group>
                        <Form.Label><b>Rank</b></Form.Label>
                      </Form.Group>
                    </Col>
                    
                    <Col xs={4}>
                      <Form.Group>
                        <Form.Label><b>Percentage</b></Form.Label>
                      </Form.Group>
                    </Col>
                    
                    <Col xs={4}>
                      <Form.Group>
                        <Form.Label><b>Winning/Person</b></Form.Label>
                      </Form.Group>
                    </Col>
                  </div>

                  { remainingPersonPercent > 100 && (
                        <div className="alert alert-warning" role="alert">
                          You cannot enter any value because the total remaining percentage equal the 100%.
                        </div>
                      )}
                   
                  {/* 
                  { totalPerson !== 0 && totalremainingPerson >= totalPerson && (
                        <div className="alert alert-warning" role="alert">
                          You cannot enter any value because the total remaining persons equal the total persons.
                        </div>
                      )} */}
                  {fields.map((field, index) => (
                    <div key={index} className="d-flex align-items-center mb-2 mt-3">
                     
                      <Form.Control
                        type="text"
                        placeholder={`Rank`}
                        className="me-2" 
                        value={field.rank}
                        onChange={(e) => handleFieldInputChange(index, 'rank', e.target.value)}
                        onBlur={(e) => handleBlurChange(index, "rank", e.target.value)}
                        // disabled={ totalremainingPerson >= totalPerson || remainingPersonPercent >= 100}
                      />
                      
                      <Form.Control
                        type="text"
                        placeholder={`Percentage`}
                        className="me-2"
                        value={field.percentage}
                        onChange={(e) => handleFieldInputChange(index, 'percentage', e.target.value)}
                        onBlur={(e) => handleBlurChange(index, "percentage", e.target.value)}
                      />
                      <Form.Control
                        type="text"
                        placeholder={`Amount Per Person`}
                        className="me-2"
                        value={field.amountPerPerson}
                        readonly
                      />
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteField(index)}
                      >
                        Delete
                      </Button>
                    </div>
                  ))}
                  
                </Form.Group>
              </Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
          { totalremainingPerson != totalPerson && totalremainingPerson == 0 || remainingPersonPercent < 100 || allTotalRank > totalPerson ? <Button type="submit" disabled variant="primary">Submit</Button> : <Button type="submit" variant="primary">Submit</Button> }
          {/* { 
  (allTotalRank > totalPerson && remainingPersonPercent < 100) ||
  (allTotalRank < totalPerson && remainingPersonPercent === 100) ||
  (allTotalRank === 0 && remainingPersonPercent === 0) 
  ? <Button type="submit" disabled variant="primary">Submit</Button> 
  : <Button type="submit" variant="primary">Submit</Button> 
} */}

        </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
