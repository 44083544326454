import  React, { useState } from "react";
import {  Button,Modal,Form, FormControl, Row, Col, Container, Card, CardBody,Badge,  } from "react-bootstrap";
import { FaPlus, FaMinus } from 'react-icons/fa';
import Table from 'react-bootstrap/Table';
import { useLocation } from 'react-router-dom';

export default function SingleTeamFormPage(props) {
  console.warn('SingleTeamFormPage calling...');
    const location = useLocation();
    
    return (
        <>
        <Container>
          <Card>
            <CardBody>
              
            <Table striped bordered hover>
      <tbody>
        <tr>
          <th>Team</th>
          <td>India</td>
          <td><input type="file" /></td>
        </tr>
        <tr>
          <th colSpan="3" style={{ textAlign: "center" }}>
            <h6>Players</h6>
          </th>
        </tr>
        <tr>
          <th>Sachin</th>
          <td colSpan="2"><input type="file" /></td>
        </tr>
        <tr>
          <th>Virat Kohli</th>
          <td colSpan="2"><input type="file" /></td>
        </tr>
        <tr>
          <td colSpan="3" style={{ textAlign: "center" }}>
            <button type="button" className="btn btn-success">Submit</button>
          </td>
        </tr>
      </tbody>
    </Table>

              </CardBody>
          </Card>
        </Container>
        
        </>
    )
}


 