import { connect } from 'react-redux';
import UserDetailPage from './UserDetail.component';
import { getAllUser } from '../../../api/user/userAction';
const mapDispatchToProps = {
    getAllUser
}
const mapStateToProps = state => ({
    isLoading: state.userPage.isLoading,
    isSaved: state.userPage.isSaved,
    result: state.userPage.result,
    metadata: state.userPage.result,
    count: 1,
});
export const UserDetailPageContainer = connect(mapStateToProps, mapDispatchToProps)(UserDetailPage); 