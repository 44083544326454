import React, { useEffect, useState } from "react";
import {Card,CardBody,Col,Container,Form,Row,Button,Accordion,Table,} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCopy,faDatabase,faCreditCard,faEyeLowVision,faBuildingColumns,faFileInvoice,faUser,faCoins,faCalendar} from "@fortawesome/free-solid-svg-icons";
import "../../../index.css";
import CountUp from "react-countup";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllUser } from "../../../api/user/userAction";
import { getAllMatch } from "../../../api/match/matchAction";



const DashboardCard = (props) => {
  const { totaleventresult } = props;
  
  const dispatch = useDispatch();
  const [totalUser, setTotalUser ]= useState(0);
  const [totalActiveUser, setTotalActiveUser ]= useState(0);
  const [totalUnverfiedUser, setTotalUnverifiedUser ]= useState(0);
  const [totalBannedUser, setTotalBannedUser ]= useState(0);
  const [totalBets, setTotalBets ]= useState(0);
  const [totalPendingBets, setTotalPendingBets ]= useState(0);
  const [totalWonBets, setTotalWonBets ]= useState(0);
  const [totalLostBets, setTotalLostBets ]= useState(0);
  const [totalRefundBets, setTotalRefundBets ]= useState(0);
  const [totalAllEvents, setTotalAllEvents ]= useState(0);
  
  const [totalRunningEvents, setTotalRunningEvents ]= useState(0);
  
  const [totalUpcomingEvents, setTotalUpcomingEvents ]= useState(0);

  useEffect(() => {
    dispatch(getAllUser());
    dispatch(getAllMatch());
  },[]);
   
  useEffect(() => {
    if(props?.props?.result?.totalUser) {
      setTotalUser(props?.props?.result?.totalUser);
    }
  },[props?.props?.result?.totalUser])

  useEffect(() => {
   if(props?.props?.totaleventresult?.data?.length) {
     
    setTotalAllEvents(props?.props?.totaleventresult?.data?.length);
    if (props?.props?.totaleventresult?.data && Array.isArray(props?.props?.totaleventresult?.data)) {
      const updatedData =
      props?.props?.totaleventresult?.data &&
      props?.props?.totaleventresult?.data.map((item, index) => ({
          ...item,
          s_n: index + 1,
          teams: Object.values(item.teams)
            .map((item) => item.name)
            .join(" | "),
        }));
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0"); 
        const day = String(today.getDate()).padStart(2, "0");
        const currentDate = `${year}-${month}-${day}`;
        const runningMatchData = updatedData.filter((match) => {
          if (!match.start_at) {
            console.warn("Invalid date:", match.start_at);
            return false;
          }
          const matchDate = new Date(match.start_at).toISOString().split("T")[0];
          return matchDate === currentDate;  
        });
      setTotalRunningEvents(runningMatchData.length);
    }
    if (props?.props?.totaleventresult?.data && Array.isArray(props?.props?.totaleventresult?.data)) {
      const updatedData =
      props?.props?.totaleventresult?.data &&
      props?.props?.totaleventresult?.data.map((item, index) => ({
          ...item,
          s_n: item.key,
          teams: Object.values(item.teams)
            .map((item) => item.name)
            .join(" | "),
        }));
        const currentDate = new Date();
        const upcomingMatchData = updatedData.filter((match) => {
          const matchDate = new Date(match.start_at);
          return matchDate > currentDate;  
        });
        setTotalUpcomingEvents(upcomingMatchData.length);
    }
   }
 },[props?.props?.totaleventresult?.data?.length])

  return (
    <div>
      <Row>
        <Col xs={6} md={3}>
          <Link to='/admin/manage-all-users' className="dash-count d-flex justify-content-between">
            <div className="dash-counts">
              <h4>
                <CountUp end={totalUser} />
              </h4>
              <h5>Total Users</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faUser}
                size="2xl"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/manage-active-users' className="dash-count das1 d-flex justify-content-between">
            <div className="dash-counts">
              <h4>
                <CountUp end={totalActiveUser} />
              </h4>

              <h5>Total Active Users</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faUser}
                size="lg"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/manage-unverified-users' className="dash-count das2 d-flex justify-content-between">
            <div className="dash-counts">
              <h4>
                <CountUp end={totalUnverfiedUser} />
              </h4>

              <h5>Total Unverified Users</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faUser}
                size="lg"
                style={{ color: "rgba(0,0,0,0.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/manage-banned-users' className="dash-count das3 d-flex justify-content-between">
            <div className="dash-counts">
              <h4>
                <CountUp end={totalBannedUser} />
              </h4>

              <h5>Total Banned Users</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faUser}
                size="lg"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col xs={6} md={3}>
          <Link to='/admin/all-bets-log' className="dash-count d-flex justify-content-between" style={{backgroundColor:'#ff6b6b'}}>
            <div className="dash-counts">
              <h4>
                <CountUp end={totalBets} />
              </h4>
              <h5>Total Bets</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faCoins}
                size="2xl"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/pending-bets-log' className="dash-count das1 d-flex justify-content-between" style={{backgroundColor:'#ffc107'}}>
            <div className="dash-counts">
              <h4>
                <CountUp end={totalPendingBets} />
              </h4>

              <h5>Total Pending Bets</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faCoins}
                size="lg"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/won-bets-log' className="dash-count das2 d-flex justify-content-between" style={{backgroundColor:'#e91e63 '}}>
            <div className="dash-counts">
              <h4>
                <CountUp end={totalWonBets} />
              </h4>

              <h5>Total Won Bets</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faCoins}
                size="lg"
                style={{ color: "rgba(0,0,0,0.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/lost-bets-log' className="dash-count das3 d-flex justify-content-between" style={{backgroundColor:'#ff9800'}}>
            <div className="dash-counts">
              <h4>
                <CountUp end={totalLostBets} />
              </h4>

              <h5>Total Lost Bets</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faCoins}
                size="lg"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col xs={6} md={3}>
          <Link to='/admin/refunded-bets-log' className="dash-count d-flex justify-content-between" style={{backgroundColor:'#673ab7 '}}>
            <div className="dash-counts">
              <h4>
                <CountUp end={totalRefundBets} />
              </h4>
              <h5>Refunded Bets</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faCoins}
                size="2xl"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/match/all-event-match' className="dash-count das1 d-flex justify-content-between" style={{backgroundColor:'#4caf50 '}}>
            <div className="dash-counts">
              <h4>
                <CountUp end={totalAllEvents} />
              </h4>

              <h5>All Events</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faCalendar}
                size="lg"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/match/running-event-match' className="dash-count das2 d-flex justify-content-between" style={{backgroundColor:'#2196f3 '}}>
            <div className="dash-counts">
              <h4>
                <CountUp end={totalRunningEvents} />
              </h4>

              <h5>Running Events</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faCalendar}
                size="lg"
                style={{ color: "rgba(0,0,0,0.15)" }}
              />
            </div>
          </Link>
        </Col>
        <Col xs={6} md={3}>
          <Link to='/admin/match/upcoming-event-match' className="dash-count das3 d-flex justify-content-between" style={{backgroundColor:'#009688 '}}>
            <div className="dash-counts">
              <h4>
                <CountUp end={totalUpcomingEvents} />
              </h4>

              <h5>Upcoming Events</h5>
            </div>
            <div className="dash-imgs">
              <FontAwesomeIcon
                icon={faCalendar}
                size="lg"
                style={{ color: "rgba(0,0,0,.15)" }}
              />
            </div>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardCard;
