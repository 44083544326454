import  React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt , faEye, faQuestion  } from '@fortawesome/free-solid-svg-icons';
import RunningEventFormDialogue from "./components/Form";
import AllEventMatchList from "./components/List";
import { getAllMatch } from "../../../api/match/matchAction";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
export default function RunningEventMatchPage(props) {
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);
    const [imagePreview, setImagePreview] = useState(null);
    const handleClose = () => setShow(false);
    const [row, setRow] = useState({});
    const dispatch = useDispatch();
    const handleShow = () => {
        setImagePreview(null);
        setShow(true)
    }
    const handleEdit = (row) => {
      const singleRow = data && data.find((val, index) => {
        if (index === row.key) {
          setRow(val);   
          return true;    
        }
        return false;  
      });
      navigate("/admin/match/featured", { state: { row } }); 
    }

    const navigate = useNavigate()

    const handleNavigate = () =>{
      navigate('/admin/match/question')
    }

    useEffect(() => {
      dispatch(getAllMatch());
      setData(data);
    },[]);
    
    useEffect(() => {
        if (props.result && Array.isArray(props.result.data)) {
          const updatedData =
            props.result &&
            props.result.data.map((item, index) => ({
              ...item,
              s_n: index + 1,
              teams: Object.values(item.teams)
                .map((item) => item.name)
                .join(" | "),
            }));
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, "0"); 
            const day = String(today.getDate()).padStart(2, "0");
            const currentDate = `${year}-${month}-${day}`;
            const filteredData = updatedData.filter((match) => {
              if (!match.start_at) {
                console.warn("Invalid date:", match.start_at);
                return false;
              }
              const matchDate = new Date(match.start_at).toISOString().split("T")[0];
              return matchDate === currentDate;  
            });
         
          setData(filteredData);
        }
      }, [props.result]);

    const columns = [
        {
          name: "S.No",
          selector: (row) => row.s_n,
          sortable: true,
        },
        {
          name: "Name",
          selector: (row) => row.name,
          sortable: true,
        },
        {
          name: "Tournament",
          selector: (row) => row.tournament.name,
          sortable: true,
        },
        {
          name: "Teams",
          selector: (row) => row.teams,
          sortable: true,
        },
        {
          name: "Venue",
          selector: (row) => row.venue.city,
          sortable: true,
        },
        {
          name: "Action",
          cell: (row) => (
            <div>
              <button
                onClick={() => handleEdit(row)}
                className="btn btn-secondary me-2"
                title="Edit"
              >
                <FontAwesomeIcon icon={faEye} />
              </button>
              <button
                onClick={() => handleNavigate()}
                className="btn btn-info text-white"
                title="Delete"
              >
                <FontAwesomeIcon icon={faQuestion} />
              </button>
            </div>
          ),
        },
    
        
         
      ];
      
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        
        }
    }
    return(
        <>
        <AllEventMatchList data={data} columns={columns} handleShow={handleShow}/>
        <RunningEventFormDialogue show={show} handleClose={handleClose} handleImageChange={handleImageChange} imagePreview={imagePreview} />
        </>
    )
    
}