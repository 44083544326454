import React from "react";
import "./DetailPage.css";
import { useNavigate } from "react-router-dom";

const DetailPage = () => {
  const navigate = useNavigate()
  return (
    <div className="container-xl px-4 my-4">
      <div className="row">
        <div className="col-xl-4">
          <div className="card mb-4 mb-xl-0">
            <div className="card-header">Profile Picture</div>
            <div className="card-body text-center">
              <img
                className="img-account-profile rounded-circle mb-2"
                src="http://bootdey.com/img/Content/avatar/avatar1.png"
                alt=""
              />
              <div className="small font-italic text-muted mb-4">
                <h4>Rakesh Jangid</h4>
                <p>Joined At 25 Sep,2024 10:39 AM</p>
              </div>
            </div>
          </div>

          <div className="card bg-white p-3 mt-3">
            <h5 className="mb-3">User Information</h5>
            <ul className="list-group">
              <li className="list-group-item d-flex justify-content-between">
                <span>Email</span>
                <span>xyz@gmail.com</span>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <span>Status</span>
                <span className="badge text-bg-success">Active</span>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <span>Balance</span>
                <span>0 USD</span>
              </li>
            </ul>
          </div>

          <div className="card bg-white p-3 mt-3">
            <h5 className="mb-3">User Action</h5>
            <ul className="list-group">
              <li className="list-group-item active" style={{cursor:'pointer'}}>Profile Setting</li>
              <li className="list-group-item" style={{cursor:'pointer'}} onClick={()=>navigate('/admin/change-password')}>Password Setting</li>
              <li className="list-group-item" style={{cursor:'pointer'}}>Manage Balance</li>
              <li className="list-group-item" style={{cursor:'pointer'}} onClick={()=>navigate('/admin/mail')}>Send Email</li>
              <li className="list-group-item" style={{cursor:'pointer'}}>Prediction Log</li>
              <li className="list-group-item" style={{cursor:'pointer'}} onClick={()=>navigate('/admin/payment')}>Payment Log</li>
              <li className="list-group-item" style={{cursor:'pointer'}}>Withdraw Log</li>
              <li className="list-group-item" style={{cursor:'pointer'}}>Transaction</li>
            </ul>
          </div>
        </div>

        <div className="col-xl-8">
          {/* Account details card*/}
          <div className="card mb-4 mt-4 mt-sm-0">
            <div className="card-header">Account Details</div>
            <div className="card-body">
              <form>
                {/* Form Row*/}
                <div className="row gx-3 mb-3">
                  <div className="col-md-6">
                    <label className="small mb-1" htmlFor="inputFirstName">
                      Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                    />
                  </div>
                  {/* Form Group (last name)*/}
                  <div className="col-md-6">
                    <label className="small mb-1" htmlFor="inputLastName">
                      Email
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      placeholder=""
                    />
                  </div>
                </div>
                {/* Form Row        */}
                <div className="row gx-3 mb-3">
                  <div className="col-md-6">
                    <label className="small mb-1" htmlFor="inputSelectMenu">
                      Gender
                    </label>
                    <select
                      className="form-control py-3"
                      aria-label="Default select example"
                    >
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                    </select>
                  </div>

                  <div className="col-md-6">
                    <label className="small mb-1" htmlFor="inputLocation">
                      Phone
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      placeholder=""
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="small mb-1" htmlFor="inputEmailAddress">
                    Address
                  </label>
                  <input className="form-control" type="text" placeholder="" />
                </div>
                {/* Form Row*/}
                <div className="row gx-3 mb-3">
                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="inputPhone">
                      Zip Code
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      placeholder=""
                    />
                  </div>

                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="inputBirthday">
                      City
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                    />
                  </div>

                  <div className="col-md-4">
                    <label className="small mb-1" htmlFor="inputBirthday">
                      Country
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder=""
                    />
                  </div>
                </div>

                {/* Form Row  */}
                <div className="row gx-3 mb-3">
                  <div className="col-md-3">
                    <label className="small mb-1" htmlFor="inputSelectMenu">
                      Status
                    </label>
                    <select
                      className="form-control py-3"
                      aria-label="Default select example"
                    >
                      <option value="1">Active</option>
                      <option value="2">Banned</option>
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label className="small mb-1" htmlFor="inputSelectMenu">
                      Email Verification
                    </label>
                    <select
                      className="form-control py-3"
                      aria-label="Default select example"
                    >
                      <option value="1">Unvarified</option>
                      <option value="2">Varified</option>
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label className="small mb-1" htmlFor="inputSelectMenu">
                      2FA Status
                    </label>
                    <select
                      className="form-control py-3"
                      aria-label="Default select example"
                    >
                      <option value="1">Unvarified</option>
                      <option value="2">Varified</option>
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label className="small mb-1" htmlFor="inputSelectMenu">
                      2FA Verification
                    </label>
                    <select
                      className="form-control py-3"
                      aria-label="Default select example"
                    >
                      <option value="1">Unvarified</option>
                      <option value="2">Varified</option>
                    </select>
                  </div>
                </div>

                {/* Save changes button*/}
                <button className="btn btn-primary" type="button">
                  Save changes
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailPage;
