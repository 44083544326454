import * as actionTypes from "./userActionType";
import axios from "axios";
const { REACT_APP_URL } = process.env;
 
export const getAllUser = () => {
  const headers = {};
    return (dispatch) => {
        dispatch(getAllUserInit());
        axios.get(`${REACT_APP_URL}/admin/userlist/?page=1&pageSize=100`, {})
          .then((response) => {
            dispatch(getAllUserSuccess(response.data));
          })
          .catch(function (error) {
            dispatch(getAllUserFailure());
          });
      };
}
export const getAllUserInit = () => ({
    type: actionTypes.GET_ALL_USER_INIT,
});
export const getAllUserFailure = () => ({
    type: actionTypes.GET_ALL_USER_FAILURE,
});
export const getAllUserSuccess = (data) => ({
    type: actionTypes.GET_ALL_USER_SUCCESS,
    payload: { data },
});

